import ResponsiveBox from 'components/layout/ResponsiveBox';
import React from 'react';
import { LayerBreakpointProps, MultiLayerBillboardProps } from './types';
import Image from 'components/Image';
import BackgroundVideo from 'components/BackgroundVideo';
import {
  ButtonWrapper,
  DesktopBreakpoint,
  ImagesWrapper,
  MobileBreakpoint,
  TextWrapper,
} from './styles';
import Link from 'components/Link';
import { ReactNode } from 'react';
import { Heading2 } from 'components/text';
import Button from 'components/Button';
import { desktop, mobile, tablet } from 'utils/media';

function BreakpointLayers({
  title,
  body,
  cta,
  layers,
  colorScheme,
  textAlignment,
}: LayerBreakpointProps) {
  const reversedLayers = [...layers].reverse();

  const bottomLayer = reversedLayers[0];

  let bottomLayerAspectRatio: number;

  if (bottomLayer._type === 'image') {
    bottomLayerAspectRatio =
      bottomLayer.asset.metadata.dimensions.height /
      bottomLayer.asset.metadata.dimensions.width;
  } else if (bottomLayer._type === 'video') {
    bottomLayerAspectRatio = bottomLayer.height / bottomLayer.width;
  } else {
    return null;
  }

  return (
    <ResponsiveBox aspectRatio={bottomLayerAspectRatio}>
      <ImagesWrapper>
        {reversedLayers.map((layer) =>
          layer._type === 'image' ? (
            <Image
              alt=""
              key={layer._key}
              src={layer.asset}
              aspectRatio={
                layer.asset.metadata.dimensions.height /
                layer.asset.metadata.dimensions.width
              }
              objectFit="contain"
              sizes="100vw"
            />
          ) : layer._type === 'video' ? (
            <BackgroundVideo
              key={layer._key}
              video={layer}
              breakpoints={{
                [mobile]: {
                  width: 750,
                  height: Math.round(750 * (layer.height / layer.width)),
                },
                [tablet]: {
                  width: 1024,
                  height: Math.round(1024 * (layer.height / layer.width)),
                },
                [desktop]: {
                  width: 1920,
                  height: Math.round(1920 * (layer.height / layer.width)),
                },
              }}
            />
          ) : null,
        )}
      </ImagesWrapper>
      <TextWrapper
        horizontalAlignment={textAlignment?.horizontal || 'center'}
        verticalAlignment={textAlignment?.vertical || 'center'}
        colorScheme={colorScheme}
      >
        <div>
          {title ? <Heading2>{title}</Heading2> : null}
          {body ? <p style={{ whiteSpace: 'pre-wrap' }}>{body}</p> : null}
          {cta?.title ? (
            <ButtonWrapper>
              <Button as="span" variant="secondary" colorScheme={colorScheme}>
                {cta.title}
              </Button>
            </ButtonWrapper>
          ) : null}
        </div>
      </TextWrapper>
    </ResponsiveBox>
  );
}

export default function MultiLayerBillboard(props: MultiLayerBillboardProps) {
  const Wrapper = ({ children }: { children: ReactNode }) =>
    props?.cta?.url ? (
      <Link href={props.cta.url}>{children}</Link>
    ) : (
      <div>{children}</div>
    );

  return (
    <Wrapper>
      {props.layers?.mobile?.length ? (
        <MobileBreakpoint>
          <BreakpointLayers
            {...props}
            textAlignment={props?.textAlignment?.mobile}
            layers={props.layers.mobile}
          />
        </MobileBreakpoint>
      ) : null}
      {props.layers?.desktop?.length ? (
        <DesktopBreakpoint>
          <BreakpointLayers
            {...props}
            textAlignment={props?.textAlignment?.desktop}
            layers={props.layers.desktop}
          />
        </DesktopBreakpoint>
      ) : null}
    </Wrapper>
  );
}
